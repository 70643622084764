import { IBase } from "./Base";

export interface IRefId {
  id: string;
  name: string;
  type: ETaskSubType;
  completed?: boolean;
}

export enum ECurrency {
  USD = "USD",
  CLP = "CLP",
  EUR = "EUR",
  UF = "UF",
}

export interface ITask extends IBase {
  name: string;
  description: string;
  type: ETaskType;
  subType: ETaskSubType;
  completed?: boolean;
  refIds?: IRefId[];
  goal: string;
  vendor: string;
  completedPercent: number;
  updatedAt: Date;
  currentValue: number;
  value: number;
  minValue: number;
  user: string;
  isQuickie: boolean;
  currency?: string;
  links?: string[];
  complianceType?: EComplianceTaskTypeEnum;
}

export enum ETaskType {
  NUMERIC = "NUMERIC",
  BOOLEAN = "BOOLEAN",
  CURRENCY = "CURRENCY",
}

export enum ETaskSubType {
  ITEM_PATH = "ITEM_PATH",
  CAREER = "CAREER",
  ACTION_PLAN = "ACTION_PLAN",
  ACTIVITY = "ACTIVITY",
  OTHER = "OTHER",
}

export enum EComplianceTaskTypeEnum {
  CERTIFICATION = "CERTIFICATION",
  COURSE = "COURSE",
  PUBLICATION = "PUBLICATION",
  WORKSHOP = "WORKSHOP",
  OTHER = "OTHER",
}

export interface ITaskData {
  id: string;
  name: string;
  icon?: string;
}
